(function ($) {
  
  window.sr = ScrollReveal({
    reset: true,
    mobile: true
  });

//left
  var efct_left = {
    origin: 'left',
    distance: '50px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 1200
  };
  sr.reveal('.js-sldl', efct_left, 100);

//left2
  var efct_left2 = {
    origin: 'left',
    distance: '50px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 1800
  };
  sr.reveal('.js-sldl2', efct_left2, 100);

//left3
  var efct_left3 = {
    origin: 'left',
    distance: '50px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 2400
  };
  sr.reveal('.js-sldl3', efct_left3, 100);

//left4
  var efct_left4 = {
    origin: 'left',
    distance: '50px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 3000
  };
  sr.reveal('.js-sldl4', efct_left4, 100);

//right
  var efct_right = {
    origin: 'right',
    distance: '50px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 1200
  };
  sr.reveal('.js-sldr', efct_right, 100);

//right2
  var efct_right2 = {
    origin: 'right',
    distance: '50px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 1800
  };
  sr.reveal('.js-sldr2', efct_right2, 100);

//right3
  var efct_right3 = {
    origin: 'right',
    distance: '50px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 2400
  };
  sr.reveal('.js-sldr3', efct_right3, 100);

//right4
  var efct_right4 = {
    origin: 'right',
    distance: '50px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 3000
  };
  sr.reveal('.js-sldr4', efct_right4, 100);

//top
  var efct_top = {
    origin: 'top',
    distance: '50px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 1200
  };
  sr.reveal('.js-sldt', efct_top, 100);

//bottom
  var efct_bottom = {
    origin: 'bottom',
    distance: '50px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 1200
  };
  sr.reveal('.js-sldb', efct_bottom, 100);

//bottom2
  var efct_bottom2 = {
    origin: 'bottom',
    distance: '150px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 1800
  };
  sr.reveal('.js-sldb2', efct_bottom2, 100);

//bottom3
  var efct_bottom3 = {
    origin: 'bottom',
    distance: '250px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 2400
  };
  sr.reveal('.js-sldb3', efct_bottom3, 100);

//bottom4
  var efct_bottom4 = {
    origin: 'bottom',
    distance: '250px',
    scale: 1,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 3000
  };
  sr.reveal('.js-sldb4', efct_bottom4, 100);

//zoomout
  var efct_zoomOut = {
    distance: '0px',
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 1200
  };
  sr.reveal('.js-zmout', efct_zoomOut, 100);

//zoomin
  var efct_zoomIn = {
    distance: '0px',
    scale: 0.5,
    rotate: {
      x: 0,
      y: 0,
      z: 0
    },
    duration: 1200
  };
  sr.reveal('.js-zmin', efct_zoomIn, 100);

//delay
  var efct_delay = {
    delay: 500
  };
  sr.reveal('.js-delay', efct_delay);

//fadein
  var efct_fade = {
    opacity: 0,
    duration: 2000
  };
  sr.reveal('.js-fadeIn', efct_fade);

})(jQuery);